<template>
  <div class="md-school-class-component">
    <div class="md-school-con" v-if="schoolListInfo.length">
      <el-carousel height="204px" :interval="0">
        <el-carousel-item v-for="(item, index) in schoolListInfo" :key="index">
          <div class="img-boxs">
            <template v-for="(s, i) in item">
              <div class="img-box" :key="i">
                <img
                  class="swiper-img"
                  :src="`/video-dev${s.avatar}`"
                  alt=""
                  v-if="s.avatar"
                />
                <div style="font-size: 20px; margin-top: 14px">
                  {{ s.name }}
                </div>
              </div>
            </template>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    schools: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schoolListInfo: [],
    };
  },
  watch: {
    schools: {
      handler(val) {
        console.log(val, "val");
        for (let i = 0; i < val.length; i += 3) {
          this.schoolListInfo.push(val.slice(i, i + 3));
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    console.log(this.schoolListInfo);
  },
};
</script>
<style lang="less" scoped>
.md-school-class-component {
  width: 100%;
  // padding-top: 50px;
  max-width: 1400px;
  margin: 0 auto;
  .md-school-con {
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    height: 204px;
    img {
      width: 100%;
      height: 100%;
    }
    .box {
      width: 1200px;
      margin: 0 auto;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .img-boxs {
      display: flex;
      height: 204px;
      width: 1200px;
    }
    .img-box {
      width: 387px;
      height: 204px;
      background: #ffffff;
      border-radius: 12px;

      border-radius: 12px;

      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .swiper-img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
/deep/ .el-carousel__container .el-carousel__item {
  display: flex;
  justify-content: center;
}
/deep/ .el-carousel__container .el-carousel__item .swiper-img {
  height: 100px;
  width: 100px !important;

  &:last-child {
    margin-right: 0px !important;
  }
}
/deep/ .el-carousel__item.is_animating {
  transition: 1s all;
}
</style>
